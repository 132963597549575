/**
 * @todo refactoring
 */
import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import Layout from '../layouts/Index'
import * as EmailValidator from 'email-validator'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import SEO from '../components/SEO'
import Style from '../styles/pages/press.module.scss'

class Press extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sending: false
    }
  }

  handleSendEmail = (e) => {
    e.preventDefault();

    const _self = this;
    const api_endpoint = this.props.pageContext.WAKATOON_PRESS_ENDPOINT;
    const formData = {
      'email': e.target.elements.email.value.trim()
    }

    let initErrors = [
      !EmailValidator.validate(formData.email) && this._t('Veuillez saisir une adresse E-mail valide.')
    ],
      errors = initErrors.filter((error) => {
        if (error) {
          return [...error];
        }
        return false;
      }),
      validForm = errors.length === 0;

    if (!validForm) {
      errors.forEach((error) => {
        return NotificationManager.error(error, null, 5000)
      });
      return false;
    }
    this.setState({ sending: true });

    formData.lang = this.props.pageContext.locale;
    fetch(api_endpoint, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": this.props.pageContext.WAKATOON_MAILER_PUBLIC_KEY
      }
    })
      .then((r) => { console.log(r.body); return r.json() })
      .then(json => {
        if (typeof json === 'object') {
          if (json.success) {
            NotificationManager.success(`${this._t(`Votre demande a bien été envoyée`)}.`, '', 3000);
          } else {
            NotificationManager.error(this._t('Une erreur est survenue.'), 'Erreur', 3000);
          }
        } else {
          NotificationManager.error(this._t('Une erreur est survenue.'), 'Erreur', 3000);
        }
        
        _self.setState({ sending: false });
        document.getElementById('pressrequest-form').reset();
      }
    );
  }

  _t = (str) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const {
      data: { seo, staticText }
    } = this.props;
    
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const _seoData = seo.edges;
    const seoData = _seoData.length > 0 ? _seoData[0].node.data : [];
    const image_left = `${this.props.pageContext.prismic_cdn}/00bb74ae-10e6-420f-b60d-a915aba6fa73_illustration_spip.png`;
    const image_right = `${this.props.pageContext.prismic_cdn}/07bb9ec2-1710-47f6-b228-58f98edfe5b1_illustration_spirou.png`;
    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={seoData.press_page_title.text}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={seoData.press_page_title.text} 
          pageDescription={seoData.press_page_description.text} 
          context={this.props.pageContext}
        />
        <div className="page Presse">
          <section className="regular">
            <React.Fragment>
              <div className={`${Style.pageHead} page-head`}>
                <h2>{this._t('Espace Presse')}</h2>
              </div>
              <div className={`${Style.container} container`}>

                <div className="illustrations illustrations-regularLeft">
                  <img 
                    src={`${this.props.pageContext.twic}/resize=2p/quality=50/${image_left}`} 
                    data-src={image_left}
                    data-src-transform="background=white/quality=80"
                    className="twic filtered-image" 
                    alt=""
                  />
                </div>

                <div className="row">
                  <div className="col-12 center">
                    <form onSubmit={this.handleSendEmail} id="pressrequest-form" className={Style.pressrequestForm}>
                      <div className={Style.noMargin} dangerouslySetInnerHTML={{
                        __html: `${this._t('Laissez-nous votre email pour recevoir notre press kit', 'html')}.`
                      }}></div>
                      <input 
                        type="text"
                        className="form-control medium"
                        placeholder={this._t('Votre e-mail')}
                        name="email"
                      />
                      <div className="form-group centered">
                        <button className="button btn btn_default">
                          {this.state.sending ? this._t('Envoi en cours') : this._t('Envoyer') }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="illustrations illustrations-regularRight">
                  <img 
                    src={`${this.props.pageContext.twic}/resize=2p/quality=50/${image_right}`} 
                    data-src={image_right}
                    data-src-transform="quality=80"
                    className="twic filtered-image" 
                    alt=""
                  />
                </div>

              </div>
            </React.Fragment>
          </section>
          <NotificationContainer />
        </div>
      </Layout>
    );
  };
};

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Press);
export const pageQuery = graphql`
  query pressPageSeo($locale: String!) {
    seo: allPrismicStaticpagesseo(filter: { lang: { eq: $locale} }) {
      edges {
        ...StaticPagesSeo
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;